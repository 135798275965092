<template>
  <v-container fluid>
    <loading-overlay :loading="loading" />

    <v-card tile flat>
      <skeleton-page-heading v-if="data_vendors.length === 0" />
      <v-card-text v-else>
        <breadcrumb-component />
        <v-divider class="tw-mb-5"></v-divider>
        <h2
          :class="
            $vuetify.breakpoint.mdAndUp
              ? 'tw-text-gray-800 tw-text-xl tw-flex tw-font-medium tw-capitalize'
              : 'tw-text-gray-800 tw-text-base tw-flex tw-font-medium tw-capitalize'
          "
        >
          <v-icon class="tw-text-gray-800 tw-mr-2"
            >mdi-account-plus-outline</v-icon
          >
          {{ title }}
        </h2>
        <v-divider class="tw-mt-5"></v-divider>
      </v-card-text>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="7" lg="7">
            <skeleton-form :count="5" v-if="data_vendors.length === 0" />
            <v-form v-else @submit.prevent="submit">
              <v-text-field
                v-model="form.name"
                label="Nama"
                placeholder="Nama"
                dense
                outlined
                autofocus
                :error-messages="errors.name"
                :disabled="loading"
              ></v-text-field>
              <v-select
                dense
                outlined
                item-text="text"
                item-value="value"
                label="Property Project"
                :disabled="loading"
                :items="property_types"
                :error-messages="errors.property_type"
                v-model="form.property_type"
              ></v-select>
              <!-- form vendor if admin -->
              <v-autocomplete
                v-if="role === 1"
                dense
                outlined
                item-text="user.name"
                item-value="id"
                label="Vendor"
                :disabled="loading"
                :items="data_vendors"
                :error-messages="errors.vendor"
                v-model="form.vendor_id"
              ></v-autocomplete>
              <!-- end -->
              <!-- form vendor if developer -->
              <v-select
                v-if="role === 2"
                dense
                outlined
                readonly
                label="Vendor"
                :items="data_vendors"
                item-text="user.name"
                item-value="id"
                :disabled="loading"
                v-model="form.vendor_id"
              ></v-select>
              <!-- end -->
              <v-autocomplete
                dense
                outlined
                item-text="name"
                item-value="id"
                label="Provinsi"
                :disabled="loading"
                :items="data_provinces"
                :error-messages="errors.province"
                v-model="form.province_id"
                @change="getDistrict(form.province_id)"
              ></v-autocomplete>
              <v-autocomplete
                v-if="form.province_id"
                dense
                outlined
                item-text="name"
                item-value="id"
                label="Kota / Kabupaten"
                :disabled="loading"
                :items="data_districts"
                :error-messages="errors.district"
                v-model="form.district_id"
              ></v-autocomplete>

              <div class="tw-text-right">
                <v-btn
                  depressed
                  class="tw-capitalize tw-tracking-wide"
                  color="primary"
                  max-width="200"
                  width="120"
                  type="submit"
                  :loading="loading"
                  >Submit</v-btn
                >
              </div>
            </v-form>
          </v-col>
          <v-col cols="5" v-if="$vuetify.breakpoint.mdAndUp">
            <v-skeleton-loader
              v-if="data_vendors.length === 0"
              class="mx-auto"
              width="250"
              type="image"
            ></v-skeleton-loader>
            <v-img
              v-else
              contain
              max-height="250"
              src="https://ik.imagekit.io/5zmdzllq3/undraw_Add_files_re_v09g_AXJ5tiXKN.svg"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import form from "@/mixins/form";
import page from "../mixins/page";
import Swal from "sweetalert2";
import { post, provinces, district, vendors } from "@/api/project";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import BreadcrumbComponent from "../components/BreadcrumbComponent.vue";
import SkeletonPageHeading from "../components/SkeletonPageHeading.vue";
import SkeletonForm from "../components/SkeletonForm.vue";

export default {
  components: {
    BreadcrumbComponent,
    LoadingOverlay,
    SkeletonPageHeading,
    SkeletonForm,
  },
  mixins: [form, page],
  data() {
    return {
      form: {
        name: "",
        province_id: "",
        district_id: "",
        vendor_id: "",
        property_type: "",
      },
      data_provinces: [],
      data_districts: [],
      data_vendors: [],
      property_types: [
        { value: "rumah", text: "Rumah" },
        { value: "apartemen", text: "Apartemen" },
        { value: "kondominium", text: "Kondominium" },
        { value: "tanah", text: "Tanah" },
        { value: "vila", text: "Vila" },
        { value: "ruko", text: "Ruko" },
        { value: "perkantoran", text: "Perkantoran" },
        { value: "pabrik", text: "Pabrik" },
        { value: "gudang", text: "Gudang" },
        { value: "ruang usaha", text: "Ruang usaha" },
      ],
    };
  },
  async created() {
    this.getProvinces();
    this.getVendors();
  },
  methods: {
    async submit() {
      try {
        this.$store.dispatch("auth/setLoading");
        await post(this.form);
        this.$store.dispatch("auth/setLoading");
        this.errors = {};
        this.$router.push({ name: "project" });
      } catch (error) {
        this.$store.dispatch("auth/setLoading");
        if (error.response.status === 403) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message,
            timer: 1500,
          });
        } else if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
      }
    },
    async getProvinces() {
      const {
        data: { data },
      } = await provinces();
      this.data_provinces = data;
    },
    async getDistrict(id) {
      const {
        data: { data },
      } = await district(id);
      this.data_districts = data;
    },
    async getVendors() {
      this.vendorId === null
        ? (this.form.vendor_id = "")
        : (this.form.vendor_id = this.vendorId);
      const {
        data: { data },
      } = await vendors();
      this.data_vendors = data;
    },
  },
  computed: {
    role() {
      return parseInt(this.$store.state.auth.profile.role_id);
    },
    vendorId() {
      return parseInt(this.$store.state.auth.profile.vendor_id);
    },
  },
};
</script>

<style></style>
