import api from "./api";

const endPoint = "/projects";

export const detail = (id) =>
  api
    .get(`${endPoint}/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const post = async (payload) =>
  await api.post(`${endPoint}`, {
    name: payload.name,
    property_type: payload.property_type,
    province: payload.province_id,
    district: payload.district_id,
    vendor: payload.vendor_id,
  });

export const update = (payload) =>
  api.put(`${endPoint}/${payload.id}`, {
    id: payload.id,
    name: payload.name,
    property_type: payload.property_type,
    province: payload.province_id,
    district: payload.district_id,
    vendor: payload.vendor_id,
  });

export const provinces = () =>
  api
    .get("/provinces/all")
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const district = (province_id) =>
  api
    .get(`/districts/show-by-province/${province_id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const vendors = () =>
  api
    .get("/vendors/all")
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const approved = (id) =>
  api
    .get(`${endPoint}/approved/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });

export const rejected = (id) =>
  api
    .get(`${endPoint}/rejected/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });
